body {

    font-family: "Noto Sans SC", sans-serif;

    .main-content {
        background: #FFF9F8;
    }

    .splitter {
        border-bottom: 1px solid #ccc;
    }

    .card {
        margin-top: 15px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
        margin-bottom: 30px;
    }
    .main-content{
        a{
            color: #E30613;
        }
    }

    i {
        color: #E30613;
    }
    .site-footer{
        i{
            color: white;
        }
    }
}


.signature-wrap {
    color: #fff;
    height: 196px;
    background-size: auto;
    position: relative;
    background: #18252D url(/img/elearning_banner-1@2x.png) no-repeat center;

    .container {
        line-height: 196px;
        position: relative;

        h2 {
            vertical-align: middle;
            display: inline-block;
            line-height: 30px;
            font-size: 20px;
            letter-spacing: 2px;
        }
    }


}
