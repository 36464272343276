.site-footer {

    background: #18252D;
    color: #fff;
    padding: 30px;

    .sub-title {
        font-size: 16px;
    }

    .trade-mark {

        p {
            font-size: 12px;
        }
    }

}
